const getUser = (state) => state.user;

export const getEmailAddress = (state) => getUser(state).emailAddress;

export const getFirstName = (state) => getUser(state).firstName;

export const getLastName = (state) => getUser(state).lastName;

export const getGender = (state) => getUser(state).gender;

export const getIfDetailsErrored = (state) => getUser(state).fatalError;

export const getIfDetailsLoaded = (state) => getUser(state).loaded;

export const getLinkedAccounts = (state) => getUser(state).linkedAccounts;

export const hasLoaded = (state) => getUser(state).loaded;

export const getHomeCountry = (state) => getUser(state).homeCountry;
